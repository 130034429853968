<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/communtiy" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
  <vxe-modal ref="xModal" title="自定义公式" width="1000" height="600" show-zoom @close="closeEvent" :mask="false">
    <template v-slot>
      <Other :dataList="fcustomFormulaList" @getDataList="getDataList" />
    </template>
  </vxe-modal>

  <vxe-modal ref="discountModal" title="优惠折扣" width="1000" height="600" show-zoom @close="closeEvent">
    <template v-slot>
      <Discount :selectRow="selectRow" />
    </template>
  </vxe-modal>
</div>
</template>

<script>
import Discount from './Discount.vue';
import Other from './Other.vue';
export default {
  components: {
    Discount,
    Other
  },
  data() {
    return {
      fcustomFormulaList: [],
      selectRow: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "create",
              name: "新增",
              status: "primary",
              events: {
                click: () => {
                  if (this.currNode.type == '1001') {
                    let { items, data } = this.formOptions;
                    this.$Tools.enabledEvent(items, ["computingFormula"], false);
                    data["parentId"] = this.currNode.id;
                    this.$refs.xGrid.insertEvent();
                  } else {
                    this.$message.error('请选择小区')
                  }
                }
              }
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: 'feeName',
            title: '项目名称',
            minWidth: 160,
            editRender: {
              name: '$input',
              props: {
                maxlength: 24,
                placeholder: "请输入项目名称"
              }
            }
          },
          {
            field: "chargeType",
            title: "收费类型",
            minWidth: 120,
            align: "center",
            editRender: {
              name: "$select",
              optionTypeCode: "chargeType",
              props: {
                placeholder: "请选择收费类型"
              }
            },
          },
          {
            field: "feeFlag",
            title: "费用标识",
            minWidth: 120,
            editRender: {
              name: "$select",
              optionTypeCode: "feeFlag",
              props: {
                placeholder: "请选择缴费周期",
              },
            },
          },
          {
            field: "feeTypeId",
            title: "费用类型",
            minWidth: 120,
            align: "center",
            editRender: {
              name: "$select",
              optionTypeCode: "costType",
              props: {
                placeholder: "请选择费用类型"
              }
            },
          },
          {
            field: "paymentCycle",
            title: "生成周期",
            minWidth: 120,
            align: "center",
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "/"
              } else {
                let options = column.editRender.options;
                let node = options.filter(item => item.code === cellValue)
                return node && node.length ? node[0].name : "/"
              }
            },
            editRender: {
              name: "$select",
              enabled: false,
              optionTypeCode: "paymentCycle",
              props: {
                placeholder: "请选择生成周期"
              }
            },
          },
          {
            field: "computingFormula",
            title: "计算公式",
            minWidth: 120,
            align: "center",
            editRender: {
              name: "$select",
              enabled: false,
              optionTypeCode: "computingFormula",
              props: {
                placeholder: "请选择计算公式"
              }
            },
          },
          {
            field: "squarePrice",
            title: "单价(元)",
            minWidth: 120,
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "/"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
              }
            },
            editRender: {
              name: "$input",
              enabled: false,
              props: {
                type: "float",
                min: 0,
                max: 9999,
                placeholder: "请输入单价（元）"
              }
            },
          },
          {
            field: "unit",
            title: "单位",
            minWidth: 120,
            formatter: ({ cellValue, column }) => {
              if (!cellValue) {
                return "/"
              } else {
                const item = column.editRender.options.find(item => item.value === cellValue)
                return item ? item.label : ''
              }
            },
            editRender: {
              name: "$select",
              optionTypeCode: "unit",
              enabled: false,
              props: {
                placeholder: "请选择单位"
              }
            },
          },
          {
            field: "fixedPrice",
            title: "固定费用",
            minWidth: 160,
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "/"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
              }
            },
            editRender: {
              enabled: false,
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 9999,
                placeholder: "请输入固定费用"
              }
            },
          },
          // {
          //   field: "enabled",
          //   title: "状态",
          //   width: 120,
          //   cellRender: {
          //     name: "$switch",
          //     optionTypeCode: "enabledStatus",
          //     props: {
          //       openLabel: "启用",
          //       closeLabel: "禁用",
          //       openValue: "1",
          //       closeValue: "0"
          //     },
          //     events: {
          //       change: ({ row }) => {
          //         this.$refs.xGrid.submitEvent(row);
          //       }
          //     }
          //   },
          // },
          {
            fixed: "right",
            title: "操作",
            width: 200,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  name: "discount",
                  status: "primary",
                  events: {
                    click: (row) => {
                      this.selectRow = row;
                      this.$refs.discountModal.open();
                    }
                  }
                },
                {
                  name: "edit",
                  status: "primary",
                  eventName: "queryEvent"
                },
                {
                  name: "delete",
                  status: "danger",
                  eventName: "removeEvent"
                }
              ]
            },
          },
        ],
        //表单校验规则
        editRules: {
          feeName: [
            { required: true, message: "请输入项目名称" },
            { min: 2, max: 30, message: "长度在 2 到 30 个字符" },
          ],
          feeTypeId: [
            { required: true, message: "请选择费用类型" },
          ],
          feeFlag: [
            { required: true, message: "请选择费用标识" },
          ],
          chargeType: [
            { required: true, message: "请选择收费类型" },
          ],
          enabled: [{ required: true, message: "请选择状态" }],
        },
        editConfig: {
          enabled: false,
        },
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            span: 24,
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "基本信息",
                position: "left"
              }
            },
          },
          {
            field: "parentId",
            title: "所属小区",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              expandAll: true,
              props: {
                placeholder: "请选择所属小区",
              },
              events: {
                click: (params, node) => {
                  let { data } = params;
                  if (node.type == '1001') {
                    data["parentId"] = node.id;
                    data["communityId"] = node.id;
                  } else {
                    this.$message.error('请选择小区')
                  }
                },
              }
            },
          },
          {
            field: "feeName",
            title: "项目名称",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入项目名称",
              },
            },
          },
          {
            field: "chargeType",
            title: "收费类型",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "chargeType",
              props: {
                placeholder: "请选择收费类型",
              },
            },
          },
          {
            field: "feeTypeId",
            title: "费用类型",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "costType",
              props: {
                placeholder: "请选择费用类型",
              },
              events: {
                change: ({ data, property }) => {
                  let { items } = this.formOptions;
                  if (data[property] == 4) {
                    data["computingFormula"] = "2";
                    this.$Tools.enabledEvent(items, ["computingFormula"], true);
                  } else {
                    data["computingFormula"] = "1";
                    this.$Tools.enabledEvent(items, ["computingFormula"], false);
                  }
                }
              }
            },
          },
          {
            field: "feeFlag",
            title: "费用标识",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "feeFlag",
              props: {
                placeholder: "请选择费用标识",
              },
              events: {
                change: ({ data, property }) => {
                  if (data[property] == "1") {
                    data["feeToMerge"] = "month"
                  }
                }
              }
            },
          },
          {
            field: "feeToMerge",
            title: "缴费周期",
            span: 12,
            visibleMethod({ data }) {
              if (data.feeFlag == 1) {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$select",
              optionTypeCode: "paymentCycle",
              props: {
                placeholder: "请选择缴费周期",
              },
              events: {
                change: ({ data, property }) => {
                  if (data[property] != "month") {
                    data["paymentCycle"] = "month"
                  }
                }
              }
            },
          },
          {
            field: "paymentCycle",
            title: "生成周期",
            span: 12,
            visibleMethod({ data }) {
              if (data.feeToMerge == "month") {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$select",
              optionTypeCode: "paymentCycle",
              props: {
                placeholder: "请选择生成周期",
              },
            },
          },
          {
            span: 24,
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "价格设置",
                position: "left"
              }
            },
          },
          {
            field: "computingFormula",
            title: "计算公式",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "computingFormula",
              props: {
                placeholder: "请选择计算公式",
              },
              events: {
                change: ({ data, property }) => {
                  if (data[property] == 1) {
                    data["fixedPrice"] = "";
                    data["computingFormulaText"] = "";
                  } else if (data[property] == 2) {
                    data["squarePrice"] = ""
                    data["unit"] = ""
                    data["computingFormulaText"] = "";
                  } else {
                    data["fixedPrice"] = "";
                    data["squarePrice"] = ""
                    data["unit"] = ""
                  }
                  this.$forceUpdate();
                }
              }
            },
          },
          {
            field: "squarePrice",
            title: "单价",
            span: 12,
            visibleMethod({ $form, data, property }) {
              let { rules } = $form;
              if (data.computingFormula == '1') {
                rules[property][0].required = true;
                return true
              } else {
                rules[property][0].required = false;
                return false;
              }
            },
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 9999,
                placeholder: "请输入单价",
              },
            },
          },
          {
            field: "unit",
            title: "单位",
            span: 12,
            visibleMethod({ $form, data, property }) {
              let { rules } = $form;
              if (data.computingFormula == '1') {
                rules[property][0].required = true;
                return true
              } else {
                rules[property][0].required = false;
                return false;
              }
            },
            itemRender: {
              name: "$select",
              optionTypeCode: "unit",
              props: {
                placeholder: "请选择单位",
              },
            },
          },
          {
            field: "fixedPrice",
            title: "固定费用",
            span: 12,
            visibleMethod({ $form, data, property }) {
              let { rules } = $form;
              if (data.computingFormula == '2') {
                rules[property][0].required = true;
                return true
              } else {
                rules[property][0].required = false;
                return false;
              }
            },
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 9999,
                placeholder: "请输入固定费用",
              },
            },
          },
          {
            field: "computingFormulaText",
            title: "计算参数",
            span: 10,
            visibleMethod({ $form, data, property }) {
              let { rules } = $form;
              if (data.computingFormula == '100') {
                rules[property][0].required = true;
                return true
              } else {
                rules[property][0].required = false;
                return false;
              }
            },
            itemRender: {
              name: "$select",
              optionTypeCode: "computingFormulaText",
              props: {
                placeholder: "请选择计算参数",
              },
            },
          },
          {
            span: 2,
            visibleMethod({ data }) {
              if (data.computingFormula == '100') {
                return true
              } else {
                return false
              }
            },
            itemRender: {
              name: "$button",
              props: {
                content: "添加",
              },
              events: {
                click: ({ data }) => {
                  let { xGrid } = this.$refs;
                  this.fcustomFormulaList = xGrid.formData.fcustomFormulaList
                  if (data.computingFormulaText) {
                    this.selectRow = data
                    this.$refs.xModal.open()
                  } else {
                    this.$message.error('请选择计算参数')
                  }
                }
              }
            },
          },
          {
            field: "enabled",
            title: "状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态",
              },
            },
          },
          {
            field: 'remark',
            title: '备注',
            span: 24,
            itemRender: {
              name: '$textarea',
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: '请输入备注内容'
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          parentId: [
            { required: true, message: "请选择所属上级" },
          ],
          feeName: [
            { required: true, message: "请输入项目名称" },
            { min: 2, max: 30, message: "长度在 2 到 30 个字符" },
          ],
          chargeType: [
            { required: true, message: "请选择收费类型" },
          ],
          feeFlag: [
            { required: true, message: "请选择费用标识" },
          ],
          feeTypeId: [
            { required: true, message: "请选择费用类型" },
          ],
          paymentCycle: [
            { required: true, message: "请选择缴费周期" },
            {
              validator: ({ itemValue, rules, data }) => {
                let { feeFlag } = data;
                if (feeFlag != 1) {
                  rules[0].required = false;
                }
                if (feeFlag == 1 && !itemValue) {
                  rules[0].required = true;
                  return new Error(`请选择缴费周期`);
                }
              },
            }
          ],
          feeToMerge: [
            { required: true, message: "请选择生成周期" },
            {
              validator: ({ itemValue, rules, data }) => {
                let { feeFlag } = data;
                if (feeFlag != 1) {
                  rules[0].required = false;
                }
                if (feeFlag == 1 && !itemValue) {
                  rules[0].required = true;
                  return new Error(`请选择生成周期`);
                }
              },
            }
          ],
          computingFormula: [
            { required: true, message: "请选择计算公式" },
          ],
          squarePrice: [
            { required: true, message: "请输入单价" },
            {
              validator: ({ itemValue, rules, data }) => {
                let { computingFormula } = data;
                if (computingFormula != 1) {
                  rules[0].required = false;
                }
                if (computingFormula == 1 && !itemValue) {
                  rules[0].required = true;
                  return new Error(`请输入单价`);
                }
              },
            }
          ],
          unit: [
            { required: true, message: "请选择单位" },
            {
              validator: ({ itemValue, rules, data }) => {
                let { computingFormula } = data;
                if (computingFormula != 1) {
                  rules[0].required = false;
                }
                if (computingFormula == 1 && !itemValue) {
                  rules[0].required = true;
                  return new Error(`请选择单位`);
                }
              },
            }
          ],
          fixedPrice: [
            { required: true, message: "请输入固定费用" },
            {
              validator: ({ itemValue, rules, data }) => {
                let { computingFormula } = data;
                if (computingFormula != 2) {
                  rules[0].required = false;
                }
                if (computingFormula == 2 && !itemValue) {
                  rules[0].required = true;
                  return new Error(`请输入固定费用`);
                }
              },
            }
          ],
          computingFormulaText: [
            { required: true, message: "请选择计算参数" },
            {
              validator: ({ itemValue, rules, data }) => {
                let { computingFormula } = data;
                if (computingFormula != 100) {
                  rules[0].required = false;
                }
                if (computingFormula == 100 && !itemValue) {
                  rules[0].required = true;
                  return new Error(`请选择计算参数`);
                }
              },
            }
          ],
          additionalAmount: [
            { required: true, message: "请输入附加费用" },
          ],
          enabled: [{ required: true, message: "请选择状态" }],
        },
        // 新增功能字段初始化
        data: {
          parentId: '',
          feeName: "",
          feeTypeId: "",
          feeFlag: "1",
          chargeType: "1",
          paymentCycle: "month",
          feeToMerge: "month",
          computingFormula: "1",
          computingFormulaText: "",
          squarePrice: "",
          unit: "square",
          enabled: "1",
          fcustomFormulaList: [],

        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "feeName",
            title: "项目名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入项目名称",
              },
            },
          },
          {
            field: "feeTypeId",
            title: "费用类型",
            itemRender: {
              name: "$select",
              optionTypeCode: "costType",
              props: {
                placeholder: "请选择费用类型",
              },
            },
          },
          {
            field: "feeFlag",
            title: "费用标识",
            itemRender: {
              name: "$select",
              optionTypeCode: "feeFlag",
              props: {
                placeholder: "请选择费用标识",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          feeName: "",
          feeFlag: "",
          feeTypeId: ""
        },
      },
    };
  },

  methods: {
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      this.formOptions.data['communityId'] = node.id;
      this.searchOptions.data['parentId'] = node.id;
      this.$refs.xGrid.getLists();
    },
    closeEvent() {
      let { xGrid } = this.$refs;
      xGrid.getLists();
    },
    getDataList(list) {
      let { xGrid } = this.$refs;
      xGrid.formData.fcustomFormulaList = list.map(item => {
        let obj = {
          startValue: item.startValue,
          endValue: item.endValue,
          customType: item.customType,
          computingFormula: item.computingFormula,
        }
        if (item.id) {
          obj.id = item.id
        }
        if (xGrid.formData.id) {
          obj.configId = xGrid.formData.id
        }
        return obj
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
