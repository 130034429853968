<template>
<div class="wrapper">
  <div class="table">
    <vxe-grid ref="xGrid" height="auto" v-bind="gridOptions" :loading="loading" :data="tableData" v-on="gridOptions.events" @toolbar-button-click="toolbarCustomEvent"></vxe-grid>
  </div>
</div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    dataList: {
      type: Array,
      default: () => {
        return [];
      },
    },

  },
  data() {
    return {
      loading: false,
      dictTypeCodes: [],
      tableData: [],
      originData: [],
      formData: {},
      // 分页的参数配置
      tablePage: {
        total: 10,
        currentPage: 1,
        pageSize: 10,
      },
      gridOptions: {
        height: 600,
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "create",
              name: "新增",
              status: "primary",
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
            {
              code: "submitEvent",
              name: "关闭并保存",
              status: "primary",
            },
            {
              name: "【L】代表楼层/读数,【例：L * 0.2 + 10】,当前楼层 * 0.2 + 固定费用 10.00。",
              status: "danger",
              type: "text"
            },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "startValue",
            title: "起始值(大于等于)",
            minWidth: 160,
            editRender: {
              name: "$input",
              autoselect: true,
              immediate: true,
              props: {
                type: 'integer',
                min: -20,
                max: 140,
                maxlength: 24,
                placeholder: "请输入起始值",
              },
            },
          },
          {
            field: "endValue",
            title: "结束值(小于)",
            minWidth: 160,
            editRender: {
              name: "$input",
              autoselect: true,
              immediate: true,
              props: {
                type: 'integer',
                min: -20,
                max: 140,
                maxlength: 24,
                placeholder: "请输入结束值",
              },
            },
          },
          {
            field: "customType",
            title: "收费类型",
            minWidth: 160,
            filters: [
              { label: '固定费用', value: '1' },
              { label: '公式', value: '2' }
            ],
            editRender: {
              name: "$select",
              options: [
                { label: '固定费用', value: '1' },
                { label: '公式', value: '2' }
              ],
              props: {
                placeholder: "请选择收费类型",
              },
            },
          },
          {
            field: "computingFormula",
            title: "费用或公式",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                placeholder: "请输入费用或公式",
              },
            },
          },
        ],
        editRules: {
          startValue: [
            { required: true, message: "请输入起始值" },
          ],
          endValue: [
            { required: true, message: "请输入结束值" },
          ],
          customType: [
            { required: true, message: "请选择收费类型" },
          ],
          computingFormula: [
            { required: true, message: "请输入费用或公式" },
            {
              validator: ({ cellValue, row, rules }) => {
                if (row.customType == '1') { // 固定
                  if (cellValue) {
                    let reg = this.$Tools.regs.decimals
                    let pattern = new RegExp(reg)
                    if (!pattern.test(cellValue)) {
                      return new Error(`请输入纯数字`);
                    }
                  } else {
                    return new Error(`请输入固定费用值`);
                  }

                }
                if (row.customType == '2') { // 公式
                  if (cellValue) {
                    let reg = this.$Tools.regs.symbol
                    let pattern = new RegExp(reg)
                    if (!pattern.test(cellValue)) {
                      return new Error(`请使用半角符号`);
                    }
                    let AZ = cellValue.replace(/[^a-zA-Z]/g, '');
                    let isL = false
                    for (let index = 0; index < AZ.length; index++) {
                      if(AZ[index] != 'L'){
                        isL = true
                      }
                    }
                    if(isL){
                      return new Error(`只能使用大写 L`);
                    }
                  } else {
                    return new Error(`请输入公式`);
                  }
                }

              },
            }
          ],
        },
        editConfig: {
          trigger: 'click',
          mode: 'cell',
          autoClear: true,
        },
        exportConfig: {},
        importConfig: {},
        events: {
          'edit-closed': (data) => {
            // this.submitRulesEvent(data);
          }
        }
      },
    };
  },
  created() {
    this.tableData = JSON.parse(JSON.stringify(this.dataList))
  },
  methods: {
    // 列表按钮组功能通用事件
    toolbarCustomEvent({ code, button }) {
      const { xGrid } = this.$refs;
      switch (code) {
        case "create":
          this.insertEvent();
          break;
        case "batchDelete":
          this.batchDeleteEvent();
          break;
        case "submitEvent":
          this.submitEvent()
          break;
        default:
          if (button.events) {
            Object.keys(button.events).forEach((key, index) => {
              if (index == 0) button.events[key](code);
            });
          }
          break;
      }
    },
    // 新增事件
    insertEvent(row) {
      let { xGrid } = this.$refs;
      let { tableData } = xGrid.getTableData()
      const record = {
        startValue: tableData.length ? tableData[tableData.length - 1].endValue : 1,
        endValue: 1,
        customType: '1',
        computingFormula: '',
      };
      record.endValue = record.startValue + 1
      this.tableData.push(
        record
      )
    },
    // 序列校验
    submitRulesEvent() {
      let { xGrid } = this.$refs;
      let { tableData } = xGrid.getTableData()
      let arr1 = tableData.map(item => item.startValue)
      let arr2 = tableData.map(item => item.endValue)
      if (!arr1.length) {
        return false
      }
      arr1.shift()
      arr2.pop()
      let isRules = arr1.every((item, index) => {
        if (arr1[index] != arr2[index]) {
          this.$message.error(`第【${index+2}】条数据的起始值，应该和第【${index+1}】条数据的结束值相等`)
        }
        return arr1[index] == arr2[index]
      })
      return isRules
    },
    // 保存提交事件
    async submitEvent() {
      let { xGrid } = this.$refs;
      const errMap = await xGrid.validate()
      if (errMap) {
        return
      }
      let { tableData } = xGrid.getTableData()
      let isValue = tableData.every((item, index) => {
        return item.startValue && item.endValue && item.customType && item.computingFormula
      })
      if (isValue) {
        let isRules = this.submitRulesEvent()
        if (isRules) {
          this.$emit('getDataList', xGrid.getTableData().tableData)
          this.$parent.close()
        }
      } else {
        this.$message.error('请补全数据后操作')
      }

    },

    // 批量删除
    batchDeleteEvent() {
      let records = this.$refs.xGrid.getCheckboxRecords();
      if (!records.length) {
        this.$message.error(this.$t("pleaseSelectDataToDelete"));
      } else {
        this.removeEvent(records);
      }
    },

    // 删除事件
    removeEvent(records) {
      this.$confirm(this.$t("isDelete"), this.$t("confirmTitle")).then(
        (type) => {
          if (type === "confirm") {
            records.forEach(record => {
              this.tableData.forEach((item, index) => {
                let key = ''
                if (item._X_ID && record._X_ID) {
                  key = '_X_ID'
                } else {
                  key = '_X_ROW_KEY'
                }
                if (item[key] == record[key]) {
                  this.tableData.splice(index, 1)
                }
              })
            })
            this.$message.success(this.$t("deleteSuccess"));
          }
        }
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.table {
  padding: 10px;
}
</style>
