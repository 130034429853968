<template>
<div class="wrapper">
  <div class="table">
    <VxeBasicTable ref="xGrid" service="/fee/fConfigDiscountRel" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
export default {
  props: ["selectRow"],
  data() {
    return {
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "create",
              name: "新增",
              status: "primary",
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "discountName",
            title: "折扣名称",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 32,
                placeholder: "请输入折扣名称",
              },
            },
          },
          {
            field: "discountType",
            title: "折扣类型",
            minWidth: 160,
            editRender: {
              name: "$select",
              optionTypeCode: "discountType",
              props: {
                placeholder: "请选择折扣类型",
              },
            },
          },
          {
            field: "discountDetail",
            title: "折扣值",
            formatter: ({ cellValue, row, column }) => {
              if (row["discountType"] == "1001") {
                return `${this.$XEUtils.commafy(Number(cellValue), { digits: 2 })}%`
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
              }
            },
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                maxlength: 24,
                placeholder: "请输入折扣值",
              },
            },
          },
          {
            field: "startTime",
            title: "开始时间",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请选择开始时间"
              }
            },
          },
          {
            field: "endTime",
            title: "结束时间",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请选择结束时间"
              }
            },
          },
        ],
        //表单校验规则
        editRules: {
          discountName: [{ required: true, message: "请输入项目名称" }, ],
          enabled: [{ required: true, message: "请选择状态" }],
        },
        editConfig: {
          enabled: false
        },
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: "discountId",
            title: "折扣",
            span: 24,
            itemRender: {
              name: "$VxeSelectList",
              service: "/fee/fFeeDiscount",
              defaultProps: {
                label: "discountName",
                value: "id",
              },
              props: {
                placeholder: "请选择折扣",
              },
              events: {
                click: (params, node) => {
                  let { data, property } = params;
                  data[property] = node.id;
                  data["discountType"] = node.discountType;
                  data["discountDetail"] = node.discountDetail;
                },
              }
            },
          },
          {
            field: "discountType",
            title: "折扣类型",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "discountType",
              props: {
                disabled: true,
                placeholder: "请选择折扣类型",
              },
            },
          },
          {
            field: "discountDetail",
            title: "折扣值",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                disabled: true,
                type: "float",
                maxlength: 24,
                placeholder: "请输入折扣值",
              },
            },
          },
          {
            field: "startTime",
            title: "开始时间",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择开始时间"
              }
            }
          },
          {
            field: "endTime",
            title: "结束时间",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择结束时间"
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          discountId: [{ required: true, message: "请选择折扣" }, ],
          startTime: [{ required: true, message: "请选择开始时间" }],
          endTime: [{ required: true, message: "请选择结束时间" }],
        },
        // 新增功能字段初始化
        data: {
          communityId: this.selectRow.communityId,
          parentId: this.selectRow.communityId,
          feeConfigId: this.selectRow.id,
          feeConfigName: this.selectRow.feeName,
          discountType: "",
          discountDetail: "",
          startTime: "",
          endTime: "",
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [],
        data: {
          communityId: this.selectRow.communityId,
          feeConfigId: this.selectRow.id,
        },
      },
    }
  },
}
</script>

<style>

</style>
